//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueClickaway from 'vue-clickaway';
import PermissionMixin from '~/mixins/permissions';

export default {
  name: 'CSidebar',
  mixins: [VueClickaway.mixin, PermissionMixin],
  props: {
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      icon: {
        size: 'regular',
      },
      links: [],
      sidebarLinks: [
        {
          name: 'Menu',
          separator: false,
          children: [
            {
              label: 'Dashboard',
              path: 'user_dashboard',
              // icon: 'o-icon o-icon--line-color o-icon--active--solid o-icon--dashboard',
              icon: 'fas fa-fw fa-house-user',
            },
            {
              label: 'Profile',
              path: 'user_profile_index',
              // icon: 'o-icon o-icon--line-color o-icon--active--solid o-icon--profile',
              icon: 'fas fa-fw fa-user',
            },
            {
              label: 'Attendances',
              path: 'user_attendance',
              // icon: 'o-icon o-icon--line-color o-icon--active--solid o-icon--attendance',
              icon: 'fas fa-fw fa-clock',
            },
            {
              label: 'Leaves',
              // icon: 'o-icon o-icon--line-color o-icon--active--solid o-icon--leave',
              icon: 'fas fa-fw fa-plane',
              children: [
                {
                  label: 'New Leave',
                  path: 'user_leave_request',
                },
                {
                  label: 'Leave Requests',
                  path: 'user_leave_history',
                  alias: ['user_leave_update', 'user_leave_detail'],
                },
                {
                  label: 'Leave Balance',
                  path: 'user_leave_balance',
                },
              ],
            },
            {
              label: 'Business Travel',
              icon: 'fas fa-fw fa-suitcase',
              children: [
                {
                  label: 'New Business Travel',
                  path: 'user_business_trip_request',
                },
                {
                  label: 'Business Travel Requests',
                  path: 'user_business_trip_list',
                  alias: ['user_business_trip_update', 'user_business_trip_detail'],
                },
              ],
            },
            {
              label: 'Expenses',
              // icon: 'o-icon o-icon--line-color o-icon--active--solid o-icon--expense',
              icon: 'fas fa-fw fa-cash-register',
              children: [
                {
                  label: 'New Expense',
                  path: 'user_expense_request',
                },
                {
                  label: 'Expense Requests',
                  path: 'user_expense_history',
                  alias: ['user_expense_update', 'user_expense_detail'],
                },
              ],
            },
            {
              label: 'Payroll',
              // icon: 'o-icon o-icon--line-color o-icon--active--solid o-icon--salary',
              icon: 'fas fa-fw fa-hand-holding-usd',
              children: [
                {
                  label: 'Payroll List',
                  path: 'user_payroll_list',
                },
                {
                  label: 'Payslip',
                  path: 'user_payroll_payslip_list',
                },
                {
                  label: 'Finance Documents',
                  path: 'user_payroll_finance_doc_list',
                },
              ],
            },
            {
              label: 'Schedules',
              // icon: 'o-icon o-icon--line-color o-icon--active--solid o-icon--schedule',
              icon: 'fas fa-fw fa-calendar-alt',
              children: [
                {
                  label: 'My Schedule',
                  path: 'user_schedule',
                },
                {
                  label: 'Department Schedule',
                  path: 'user_schedule_department_list',
                },
              ],
            },
          ],
        },
        {
          name: 'Administration',
          separator: false,
          children: [
            {
              label: 'Employees',
              path: 'admin_employee_index',
              icon: 'fas fa-fw fa-user-friends',
              children: [
                {
                  label: 'Employee List',
                  path: 'admin_employee_list',
                },
                {
                  label: 'Export Employee',
                  path: 'admin_employee_export_list',
                },
                {
                  label: 'Employee Transfer',
                  path: 'admin_employee_transfer_list',
                },
                {
                  label: 'Departments',
                  path: 'admin_department_list',
                  // icon: 'fas fa-fw fa-user-shield',
                },
                {
                  label: 'Job Categories',
                  path: 'admin_jobcategory_index',
                  // icon: 'fas fa-fw fa-user-tag',
                },
                {
                  label: 'Positions',
                  path: 'admin_position_list',
                  // icon: 'fas fa-fw fa-user-tie',
                },
                {
                  label: 'Warning Types',
                  path: 'admin_employee_warning_list',
                },
              ],
            },
            {
              label: 'Recruitment',
              path: 'admin_recruitment_index',
              icon: 'far fa-id-card',
              children: [
                {
                  label: 'Employee Requisition',
                  path: 'admin_recruitment_employee_request_list',
                },
              ],
            },
            {
              label: 'Attendances',
              path: 'admin_leave_index',
              icon: 'fas fa-clock',
              children: [
                {
                  label: 'Attendance List',
                  path: 'admin_attendance_list',
                  alias: ['admin_attendance_detail'],
                },
                {
                  label: 'Attendance Report',
                  path: 'admin_attendance_report',
                },
                {
                  label: 'Resync Attendance',
                  path: 'admin_attendance_resync_list',
                },
                {
                  label: 'Export Attendance',
                  path: 'admin_attendance_export_attendance_list',
                },
                {
                  label: 'Export Punch Data',
                  path: 'admin_attendance_export_list',
                },
              ],
            },
            {
              label: 'Leaves',
              path: 'admin_leave_index',
              icon: 'fas fa-fw fa-plane',
              children: [
                {
                  label: 'Leave Requests',
                  path: 'admin_leave_history',
                  alias: ['admin_leave_detail'],
                },
                {
                  label: 'Leave Balances',
                  path: 'admin_leave_balance_list',
                },
                {
                  label: 'Approval Level',
                  path: 'admin_leave_approval_level_list',
                },
                {
                  label: 'Leave Types',
                  path: 'admin_leave_type',
                },
              ],
            },
            {
              label: 'Business Travel',
              path: 'admin_business_trip_index',
              icon: 'fas fa-fw fa-suitcase',
              children: [
                {
                  label: 'Business Travel Requests',
                  path: 'admin_business_trip_list',
                  alias: ['admin_business_trip_detail'],
                },
              ],
            },
            {
              label: 'Expenses',
              icon: 'fas fa-fw fa-cash-register',
              children: [
                {
                  label: 'Expense Requests',
                  path: 'admin_expense_history',
                },
                {
                  label: 'Approval Level',
                  path: 'admin_expense_approval_level_list',
                },
                {
                  label: 'Expense Types',
                  path: 'admin_expense_type',
                },
              ],
            },
            {
              label: 'Payroll',
              icon: 'fas fa-fw fa-hand-holding-usd',
              children: [
                {
                  label: 'Payroll List',
                  path: 'admin_payroll_list',
                },
                {
                  label: 'Payslip',
                  path: 'admin_payroll_payslip_list_draft',
                },
                {
                  label: 'Export Payslip',
                  path: 'admin_payroll_export_list',
                },
                {
                  label: 'Import Payslip',
                  path: 'admin_payroll_import_list',
                },
                {
                  label: 'Finance Documents',
                  path: 'admin_payroll_finance_doc_list',
                },
                {
                  label: 'Fixed Allowances',
                  path: 'admin_setting_payroll_fixed_allowance_list',
                },
                {
                  label: 'Deductions',
                  path: 'admin_setting_payroll_deductions_list',
                },
                {
                  label: 'Taxes',
                  path: 'admin_setting_payroll_taxes_list',
                },
                {
                  label: 'Salary Reasons',
                  path: 'admin_setting_payroll_new_salary_reason_list',
                },
              ],
            },
            {
              label: 'Schedules',
              icon: 'fas fa-fw fa-calendar-alt',
              children: [
                {
                  label: 'Employee Schedule',
                  path: 'admin_schedule_employee_list',
                  alias: [
                    'admin_schedule_employee_index',
                    'admin_schedule_employee_list',
                    'admin_schedule_employee_new',
                    'admin_schedule_employee_update',
                  ],
                },
                {
                  label: 'Department Shift Schedule',
                  path: 'admin_schedule_department_shift_list',
                  alias: [
                    'admin_schedule_department_shift_index',
                    'admin_schedule_department_shift_list',
                    'admin_schedule_department_shift_new',
                    'admin_schedule_department_shift_update',
                  ],
                },
                {
                  label: 'Work Hour',
                  path: 'admin_schedule_work_hour_list',
                  alias: [
                    'admin_schedule_work_hour_index',
                    'admin_schedule_work_hour_list',
                    'admin_schedule_work_hour_new',
                    'admin_schedule_work_hour_update',
                  ],
                },
                {
                  label: 'Fixed Schedule Type',
                  path: 'admin_schedule_fixed_schedule_type_list',
                  alias: [
                    'admin_schedule_fixed_schedule_type_index',
                    'admin_schedule_fixed_schedule_type_list',
                    'admin_schedule_fixed_schedule_type_new',
                    'admin_schedule_fixed_schedule_type_update',
                  ],
                },
              ],
            },
            {
              label: 'Holiday & Event',
              path: 'admin_setting_holiday_event_list',
              icon: 'fas fa-fw fa-calendar-check',
              alias: [
                'admin_setting_holiday_event_index',
                'admin_setting_holiday_event_list',
                'admin_setting_holiday_event_new',
                'admin_setting_holiday_event_update',
              ],
            },
            {
              label: 'Access Rights',
              path: 'admin_access_index',
              icon: 'fas fa-key',
              children: [
                // {
                //   label: 'Employee Roles',
                //   path: 'admin_access_list',
                // },
                {
                  label: 'Roles',
                  path: 'admin_access_role_list',
                  alias: ['admin_access_role_create', 'admin_access_role_detail', 'admin_access_role_update'],
                },
                {
                  label: 'Permissions',
                  path: 'admin_access_permission',
                },
              ],
            },
            {
              label: 'Setting',
              icon: 'fas fa-fw fa-cogs',
              children: [
                {
                  label: 'Company',
                  path: 'admin_setting_company',
                },
                {
                  label: 'Currency',
                  path: 'admin_setting_currency',
                },
                {
                  label: 'Employee Data',
                  path: 'admin_setting_employee',
                },
                {
                  label: 'Attendance',
                  path: 'admin_setting_attendance',
                },
                {
                  label: 'Leave',
                  path: 'admin_setting_leave',
                },
                {
                  label: 'Business Travel',
                  path: 'admin_setting_business_trip',
                },
                {
                  label: 'Expense',
                  path: 'admin_setting_expense',
                },
                // {
                //   label: 'User',
                //   path: 'admin_setting_user',
                // },
                {
                  label: 'Payroll',
                  path: 'admin_setting_payroll',
                },
              ],
            },
          ],
        },
      ],
    };
  },
  async mounted() {
    this.links = await this.validateMenus();
  },
  methods: {
    toggle() {
      this.$emit('clickBurger');
    },
    async validateMenus() {
      const finalLinks = [];
      this.sidebarLinks.forEach(async (x, i) => {
        const result = await this.validateMenuGroup(x);
        finalLinks.push(result);
      });

      return finalLinks;
    },
    async validateMenuGroup(group) {
      const result = [];

      let childCount = 0;
      let inaccessibleCount = 0;

      if (group.children) {
        childCount = group.children.length;
        for (let index = 0; index < group.children.length; index += 1) {
          const child = group.children[index];
          if (child.children) {
            // eslint-disable-next-line no-await-in-loop
            const res = await this.validateMenuGroup(child);
            if (!res.accessible) inaccessibleCount += 1;

            result.push({
              ...child,
              accessible: res.accessible,
              children: res.children,
              child_count: res.child_count,
              inaccessible_count: res.inaccessible_count,
            });
          } else {
            // eslint-disable-next-line no-await-in-loop
            const accessibility = await this.check(child.path);
            if (!accessibility) inaccessibleCount += 1;
            result.push({
              ...child,
              accessible: accessibility,
            });
          }
        }
      }

      return {
        ...group,
        accessible: childCount !== inaccessibleCount,
        children: result,
        child_count: childCount,
        inaccessible_count: inaccessibleCount,
      };
    },
  },
};
