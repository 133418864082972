import r from '~/utils/render-utils';

// Profile
const Index = () => import(/* webpackChunkName: 'user_profile_index' */ '~/pages/user/profile/Index');
const Dashboard = () => import(/* webpackChunkName: 'user_profile_dashboard' */ '~/pages/user/profile/Dashboard');

const PersonalIndex = () => import(/* webpackChunkName: 'user_profile_personal' */ '~/pages/user/profile/personal/Index');
const PersonalList = () => import(/* webpackChunkName: 'user_profile_personal' */ '~/pages/user/profile/personal/List');
const PersonalForm = () => import(/* webpackChunkName: 'user_profile_personal' */ '~/pages/user/profile/personal/Form');

const FamilyIndex = () => import(/* webpackChunkName: 'user_profile_family_index' */ '~/pages/user/profile/family/Index');
const FamilyList = () => import(/* webpackChunkName: 'user_profile_family_list' */ '~/pages/user/profile/family/List');
const FamilyCreate = () => import(/* webpackChunkName: 'user_profile_family_create' */ '~/pages/user/profile/family/Create');
const FamilyUpdate = () => import(/* webpackChunkName: 'user_profile_family_update' */ '~/pages/user/profile/family/Update');

const EducationIndex = () => import(/* webpackChunkName: 'user_profile_education_index' */ '~/pages/user/profile/education/Index');
const EducationList = () => import(/* webpackChunkName: 'user_profile_education_list' */ '~/pages/user/profile/education/List');
const EducationCreate = () => import(/* webpackChunkName: 'user_profile_education_create' */ '~/pages/user/profile/education/Create');
const EducationUpdate = () => import(/* webpackChunkName: 'user_profile_education_update' */ '~/pages/user/profile/education/Update');

const JobIndex = () => import(/* webpackChunkName: 'user_profile_job_index' */ '~/pages/user/profile/job/Index');
const JobList = () => import(/* webpackChunkName: 'user_profile_job_list' */ '~/pages/user/profile/job/List');
const JobCreate = () => import(/* webpackChunkName: 'user_profile_job_create' */ '~/pages/user/profile/job/Create');
const JobUpdate = () => import(/* webpackChunkName: 'user_profile_job_update' */ '~/pages/user/profile/job/Update');

const FinanceIndex = () => import(/* webpackChunkName: 'user_profile_finance_index' */ '~/pages/user/profile/finance/Index');
const FinanceList = () => import(/* webpackChunkName: 'user_profile_finance_list' */ '~/pages/user/profile/finance/List');
const FinanceUpdate = () => import(/* webpackChunkName: 'user_profile_finance_update' */ '~/pages/user/profile/finance/Update');

const PayrollIndex = () => import('~/pages/user/profile/payroll/Index');
const PayrollList = () => import('~/pages/user/profile/payroll/List');
const PayrollView = () => import('~/pages/user/profile/payroll/View');

const Position = () => import('~/pages/user/profile/Position');
const File = () => import('~/pages/user/profile/File');
const Training = () => import('~/pages/user/profile/Training');
const Warning = () => import('~/pages/user/profile/Warning');
const Bonding = () => import('~/pages/user/profile/BondingContract');

export default {
  path: '/profile',
  component: r(Index),
  children: [
    {
      path: '',
      name: 'user_profile_index',
      redirect: '/profile/personal',
    },
    {
      path: 'dashboard',
      name: 'user_profile_dashboard',
      component: r(Dashboard),
    },
    {
      path: 'personal',
      component: r(PersonalIndex),
      children: [
        {
          path: '',
          name: 'user_profile_personal_list',
          component: r(PersonalList),
        },
        {
          path: 'form',
          name: 'user_profile_personal_form',
          component: r(PersonalForm),
        },
      ],
    },
    {
      path: 'family',
      component: r(FamilyIndex),
      children: [
        {
          path: '',
          name: 'user_profile_family_list',
          component: r(FamilyList),
        },
        {
          path: 'new',
          name: 'user_profile_family_create',
          component: r(FamilyCreate),
        },
        {
          path: ':fid',
          name: 'user_profile_family_update',
          component: r(FamilyUpdate),
        },
      ],
    },
    {
      path: 'education',
      component: r(EducationIndex),
      children: [
        {
          path: '',
          name: 'user_profile_education_list',
          component: r(EducationList),
        },
        {
          path: 'new',
          name: 'user_profile_education_create',
          component: r(EducationCreate),
        },
        {
          path: ':fid',
          name: 'user_profile_education_update',
          component: r(EducationUpdate),
        },
      ],
    },
    {
      path: 'job',
      component: r(JobIndex),
      children: [
        {
          path: '',
          name: 'user_profile_job_list',
          component: r(JobList),
        },
        {
          path: 'new',
          name: 'user_profile_job_create',
          component: r(JobCreate),
        },
        {
          path: ':fid',
          name: 'user_profile_job_update',
          component: r(JobUpdate),
        },
      ],
    },
    {
      path: 'finance',
      component: r(FinanceIndex),
      children: [
        {
          path: '',
          name: 'user_profile_finance_list',
          component: r(FinanceList),
        },
        {
          path: 'form',
          name: 'user_profile_finance_update',
          component: r(FinanceUpdate),
        },
      ],
    },
    {
      path: 'position',
      name: 'user_profile_position',
      component: r(Position),
    },
    /* {
      path: 'payroll',
      component: r(PayrollIndex),
      children: [
        {
          path: '',
          name: 'user_profile_payroll_list',
          component: r(PayrollList),
        },
        {
          path: 'view/:fid',
          name: 'user_profile_payroll_view',
          component: r(PayrollView),
        },
      ],
    }, */
    {
      path: 'file',
      name: 'user_profile_file',
      component: r(File),
    },
    {
      path: 'training-certification',
      name: 'user_profile_training',
      component: r(Training),
    },
    {
      path: 'warning',
      name: 'user_profile_warning',
      component: r(Warning),
    },
    {
      path: 'bonding-contract',
      name: 'user_profile_bonding',
      component: r(Bonding),
    },
  ],
};
