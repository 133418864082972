import ApiClient from '~/api';

const initialState = {
  documents: [],
  storageUsed: 0,
};

const getters = {
  documents: state => state.documents,
  storageUsed: state => state.storageUsed,
};

const actions = {
  async fetchDocuments(_, queries) {
    try {
      const { data } = await ApiClient.v1.document.getDocuments(queries);
      return data;
    } catch (e) {
      return [];
    }
  },
  uploadDocument(_, formData) {
    return ApiClient.v1.document.uploadDocument(formData);
  },
  deleteDocument(_, { id, formData }) {
    return ApiClient.v1.document.deleteDocument(id, formData);
  },
  resyncDocuments(_, { uuid, formData }) {
    return ApiClient.v1.document.resyncDocuments(uuid, formData);
  },
  async fetchStorageUsed({ commit }) {
    try {
      const { data } = await ApiClient.v1.document.getStorageSize();
      commit('setStorageUsed', data.value);
    } catch (e) {
      commit('setStorageUsed', 0);
      throw e;
    }
  },
  async fetchDocumentsFile(_, filename) {
    try {
      return await ApiClient.v1.document.getDocumentsFile(filename);
    } catch (e) {
      return null;
    }
  },
  async fetchDocumentsFileGeneral(_, filename) {
    try {
      return await ApiClient.v1.document.getDocumentsFileGeneral(filename);
    } catch (e) {
      return null;
    }
  },
  async fetchUnmappedFile(_, query) {
    try {
      return await ApiClient.v1.document.getUnmappedFile(query);
    } catch (e) {
      return null;
    }
  },
  async deleteBulkFiles({ dispatch }, { formData }) {
    try {
      const { data } = await ApiClient.v1.document.deleteBulkFiles(formData);
      return { errors: null, data };
    } catch (e) {
      if (e.response && e.response.status && (e.response.status === 400 || e.response.status === 422) && e.response.data && e.response.data.errors) {
        return { errors: e, data: [] };
      }

      const caption = 'Unable to delete files';
      dispatch('error/catchError', { error: e, caption }, { root: true });
      return { errors: e, data: [] };
    }
  },
};

const mutations = {
  setStorageUsed(state, value) {
    state.storageUsed = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
